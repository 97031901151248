<template>
  <div class="fileMain" v-loading="applyLoad">
    <div class="uploadBox">
      <h2>豸信CA验签报告-{{reportType===0?'电子版':'纸质版'}}申请</h2>
      <div class="upload" v-show="!file||!file.name">
        <el-upload ref="elUpload" :http-request="selectFile" action="" :on-success="handleSuccess" :before-upload="beforeUpload" drag :show-file-list="false" accept="application/pdf">
          <img class="icon" src="@/assets/icons/service/uoload-icon.png">
          <h4>点击上传签署文档</h4>
          <p>暂支持50M以内PDF格式文档上传；支持符合GB38540和 ISO32000规范PDF验证</p>
        </el-upload>
      </div>
      <template v-if="file&&file.name">
        <div class="file">
          <img src="@/assets/icons/service/filesUpload.png" class="left-icon">
          <div class="title">
            <img class="icon" src="@/assets/icons/service/pdf-icon.png">
            <div class="name">{{file.name}}</div>
            <el-button @click="uploadAgain" type="text">重新上传 </el-button>
          </div>
        </div>
      </template>
    </div>
    <div class="receiveWay" v-if="reportType!==0">
      <h3>领取方式</h3>
      <el-form :model="information" :rules="rules" ref="ruleForm" label-position="left">
        <el-form-item label="领取方式" prop="receiveType">
          <el-radio-group v-model="information.receiveType">
            <el-radio :label="1">邮寄到付</el-radio>
            <el-radio :label="0">自取</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="information.receiveType===1">
          <div class="recipientInfo clearfix">
            <el-form-item label="收件人" prop="receiver" size="small">
              <el-input v-model="information.receiver" placeholder="请输入收件人" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="收件人联系电话" prop="phone" size="small">
              <el-input v-model="information.phone" placeholder="请输入收件人联系电话" maxlength="30"></el-input>
            </el-form-item>
          </div>
          <div class="shippingAddress">
            <el-form-item label="收件地址" prop="stateOrProvince" size="small">
              <el-cascader v-model="provinceAndCity" @change="provinceAndCityCg" :props="{value:'label'}" :options="citysData" placeholder="请选择省市"></el-cascader>
            </el-form-item>
            <el-form-item label="" prop="address" size="small">
              <el-input v-model="information.address" placeholder="请输入详细地址" maxlength="200" size="small"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="officeLocation" v-else>
          <span><i>*</i>自取地址</span>
          <p>山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）</p>
          <p>咨询电话：400-030-2013</p>
        </div>
      </el-form>
    </div>
    <div class="applyVerification ">
      <el-button type="primary" @click="applyVerification(false)">立即申请</el-button>
    </div>
  </div>
</template>

<script>
import citysData from '@/utils/citys'
import request from '@/utils/request'
// import { validSpace } from '@/utils/validate'
export default {
  data () {
    return {
      applyLoad: false,
      file: {},
      citysData: [],
      reportType: 0,
      provinceAndCity: '',
      pdfPath: '',
      information: {
        receiveType: 1,
        receiver: '',
        phone: '',
        stateOrProvince: '',
        address: '',
      },
      rules: {
        receiveType: [
          { required: true, message: '请选择领取方式', trigger: 'change' }
        ],
        receiver: [
          { required: true, message: '请输入收件人姓名', trigger: 'change' },
          {
            required: true,
            transform: (value) => value && value.trim(),
            message: '请输入正确的收件人姓名',
            trigger: 'change'
          }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'change' },
          {
            pattern: /^(0\d{2,3}-)?([2-9]\d{6,7})+(-\d{1,6})?$|^((\+86|\+86-)|(86|86-)|(0086|0086-))?1[3|5|7|8]\d{9}$/, message: '请输入正确的联系电话', trigger: 'blur'
          }
        ],
        stateOrProvince: [
          { required: true, message: '请选择收件地址', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
    }
  },
  created () {
    this.clearCitysData()
  },
  mounted () {
    this.information.receiver = this.$store.state.user.userInfo.name
    this.information.phone = this.$store.state.user.userMobile
    this.$bus.$on('afterLogin', () => { })
  },
  methods: {
    clearCitysData () {
      for (let province of citysData) {
        for (let city of province.children) {
          delete city.children
        }
      }
      this.citysData = citysData
    },
    provinceAndCityCg () {
      this.information.stateOrProvince = this.provinceAndCity[0]
      this.information.locality = this.provinceAndCity[1]
    },

    handleSuccess (res) {
      if (res.code === '0') {
        this.pdfPath = res.data.filePath
      } else {
        this.pdfPath = ''
        this.file = {}
        this.$message.error(res.msg)
      }
    },
    beforeUpload (file) {
      const isPDF = file.type === 'application/pdf'
      const isLt50M = file.size / 1024 / 1024 < 50
      const fileName = file.name.split('.pdf')[0].length
      if (!isPDF) {
        this.$message.error('文档格式错误，请上传正确格式文档！')
        return false
      }
      if (!isLt50M) {
        const h = this.$createElement
        this.$alert(
          h('p', undefined, [
            h('span', undefined, '您上传的文件过大，请下载'),
            h(
              'a',
              {
                on: {
                  click: () => {
                    this.$util.downloadById(this.CONFIG.CLIEN_SIDE_ID)
                  }
                }
              },
              '豸信CA签章客户端'
            ),
            h('span', undefined, '进行验证')
          ]),
          '提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '好的',
            type: 'warning'
          }
        )
        return false
      }
      if (fileName > 60) {
        this.$message.error('请上传文件名称不超过60字的文件')
        return false
      }
      this.file = file
    },

    uploadAgain () {
      this.result = null
      this.$refs.elUpload.$children[0].handleClick()
    },
    applyVerification (forcesubmit) {
      if (JSON.stringify(this.file) === "{}") {
        this.$message.error('请上传签署文档')
        return false
      }
      let deliveryAddress = ''
      if (this.reportType !== 0 && this.information.receiveType === 1) {
        deliveryAddress = this.information.stateOrProvince + this.information.locality + this.information.address
      }

      let data = new FormData()
      data.append('file', this.file)
      data.append('transactionType', this.reportType)
      data.append('forceSubmit', forcesubmit)
      if (this.reportType !== 0) {
        data.append('receiveType', this.information.receiveType)
        if (this.information.receiveType === 1) {
          data.append('receiver', this.information.receiver)
          data.append('phone', this.information.phone)
          data.append('deliveryAddress', deliveryAddress)
        }
      }

      if (this.reportType !== 0) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.behaviorVerification(data)
          }
        })
      } else {
        this.behaviorVerification(data)
      }
    },
    behaviorVerification (data) {
      this.$tencentCaptcha(val => {
        data.append('randStr', val.randStr)
        data.append('ticket', val.ticket)

        this.applyLoad = true
        request({
          url: '/portal/report/trans-submit',
          method: 'post',
          data: data,
          skipErr: true,
        }).then(res => {
          this.applyLoad = false
          if (res.code === '0') {
            this.$parent.stepActive = 3
            this.$router.push({ query: { transactionId: res.data.transactionId }, })
            this.$parent.transactionId = res.data.transactionId
          } else if (res.code === '1') {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '好的',
              showCancelButton: false,
              type: 'warning'
            }).then(() => { }).catch(() => { });
          } else if (res.code === '201') {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '确定提交',
              cancelButtonText: '取消申请',
              type: 'warning'
            }).then(() => {
              this.applyVerification(true)
            }).catch(() => { });
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    selectFile () {

    }
  }
}
</script>

<style lang="scss" scoped>
.fileMain {
  padding: 56px 176px 50px;
}
.uploadBox {
  h2 {
    font-size: 18px;
    color: #333333;
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;
  }
}
.upload {
  width: 948px;
  height: 209px;
  ::v-deep .el-upload-dragger {
    width: 948px;
    height: 209px;
    background: #fbfcff;
    border-color: #b9c2f2;
    text-align: center;
  }
  img {
    width: 69px;
    margin: 32px 0 12px;
  }
  h4 {
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: normal;
  }
  p {
    margin-top: 16px;
    font-size: 14px;
    color: #7789a4;
    line-height: 19px;
    letter-spacing: 1px;
  }
}
.file {
  width: 948px;
  height: 72px;
  background: #f7faff;
  border-radius: 2px;
  border: 1px dashed #b9c2f2;
  .left-icon {
    width: 24px;
    height: 70px;
    float: left;
  }
  .title {
    height: 70px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon {
    width: 24px;
  }
  .name {
    flex: auto;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.receiveWay {
  padding-top: 24px;
  h3 {
    font-size: 14px;
    color: #303b50;
    line-height: 50px;
    padding-left: 11px;
    position: relative;
    border-bottom: 1px solid #dee2e8;
    margin-bottom: 12px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 16px;
      left: 0;
    }
  }
  .el-form-item {
    margin-bottom: 4px;
  }
  .el-form-item__label {
    color: #303b50;
  }
  .el-radio {
    color: #303b50;
  }
}
.recipientInfo {
  width: 100%;
  margin-bottom: 14px;
  .el-form-item {
    width: 390px;
    float: left;
    margin-right: 24px;
  }
}
.shippingAddress {
  width: 804px;
  .el-form-item {
    margin-bottom: 24px;
  }
  ::v-deep .el-form-item__label {
    width: 100%;
  }
  .el-cascader {
    width: 390px;
  }
}
.applyVerification {
  padding-top: 58px;
  text-align: center;
  .el-button {
    width: 150px;
    height: 40px;
  }
}
.officeLocation {
  padding-bottom: 100px;
  span {
    display: block;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  i {
    color: #ff5846;
    margin-right: 7px;
  }
  p {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    margin-bottom: 20px;
  }
}
</style>