<template>
  <div class="paymentBox" v-loading="infoLoad">
    <h2>业务支付</h2>
    <div class="paymentInfo clearfix">
      <h3>业务单信息</h3>
      <p><span>业务单名称：</span>{{detailInfo.transactionName}}</p>
      <p><span>业务单号：</span>{{detailInfo.transactionNo}}</p>
      <p><span>业务类型：</span>{{detailInfo.transactionType===0?'电子版':'纸质版'}}</p>
      <p><span>创建时间：</span>{{detailInfo.createDate}}</p>
      <p><span>业务单状态：</span>{{detailInfo.transactionStatus|reportStatus}}</p>
    </div>
    <div class="paymentInfo clearfix" v-if="detailInfo.transactionType!==0">
      <h3>领取方式</h3>
      <p><span>领取方式：</span>{{detailInfo.receiveType===1?'邮寄到付':'自取'}}</p>
      <div v-if="detailInfo.receiveType===1">
        <p><span>收件人：</span>{{detailInfo.receiver}}</p>
        <p><span>联系电话：</span>{{detailInfo.phone}}</p>
        <p><span>收件地址：</span>{{detailInfo.deliveryAddress}}</p>
      </div>
    </div>
    <div class="paymentDetails">
      <p><span>总计</span><i>¥</i><em>{{detailInfo.amount}}</em>（支持在线开票）</p>
      <h3><img src="@/assets/icons/service/wx-peyment-icon.png">微信支付</h3>
      <dl>
        <dt>
          <div class="onPayment" v-if="onPayment">
            <i class="el-icon-success icon"></i>
            <div>付款中，请稍后…</div>
          </div>
          <img :src="qrCodeSrc">
        </dt>
        <dd @click="generatePayQrcode(true)">重新生成二维码</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { reportStatus } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailsLoad: false,
      onPayment: false,
      timeout: '',
      qrCodeSrc: '',
      detailInfo: '',
      infoLoad: false
    }
  },
  props: ['transactionId'],
  filters: {
    reportStatus
  },
  mounted () {
    this.getDetail()
    this.generatePayQrcode(true)
  },
  methods: {
    getDetail () {
      this.infoLoad = true
      this.$api.getReportDetail({ transactionId: this.transactionId }).then(res => {
        this.infoLoad = false
        if (res.code === '0') {
          this.detailInfo = res.data
        }
      })
    },
    generatePayQrcode (reset) {
      this.infoLoad = true
      clearTimeout(this.timeout)
      this.$api.getReportPayQrcode({ transactionId: this.transactionId, reset: reset }).then(res => {
        this.infoLoad = false
        if (res.code === '0') {
          this.qrCodeSrc = 'data:image/jpg;base64,' + res.data.body
          this.getIsPay()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getIsPay () {
      let self = this
      this.$api.getIsPay({
        transactionId: this.transactionId
      }).then(res => {
        let code = res.code
        if (code === '0') {
          this.$parent.stepActive = 4
        } else if (code === '1') {
          this.timeout = setTimeout(() => {
            self.getIsPay()
          }, 3000)
        } else if (code === '-1' || code === '2') {
          this.errorTime = setTimeout(() => {
            this.generatePayQrcode(true)
          }, 3000);
        } else if (code === '-2') {
          this.onPayment = true
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    this.timeout = ''
    clearTimeout(this.errorTime)
    this.errorTime = ''
  }
}
</script>

<style lang="scss" scoped>
.paymentBox {
  h2 {
    height: 52px;
    padding-left: 32px;
    font-size: 16px;
    color: #333333;
    line-height: 52px;
    border-bottom: 1px solid #dee2e8;
  }
}
.paymentInfo {
  padding: 24px 32px 14px;
  h3 {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 24px;
    &::before {
      position: absolute;
      width: 3px;
      height: 16px;
      content: '';
      background: #1c67f3;
      top: 1px;
      left: 0;
    }
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 24px;
    width: 100%;
    float: left;
    &:nth-child(3) {
      width: 33%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(5) {
      width: 20%;
    }
    &:last-child {
      width: 100%;
      margin: 0;
    }
  }
  span {
    color: #6c737b;
  }
}
.paymentDetails {
  padding: 24px 32px;
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 21px;
  }
  span {
    font-weight: bold;
    margin-right: 24px;
  }
  i {
    font-size: 16px;
    color: #fbb900;
  }
  em {
    color: #fbb900;
    font-size: 24px;
  }
  h3 {
    width: 114px;
    height: 38px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #1c67f3;
    font-weight: normal;
    font-size: 14px;
    color: #303b50;
    line-height: 38px;
    padding: 0 10px;
    margin-top: 24px;
    background: url('~@/assets/image/personCenter/selected.png') 98px 23px
      no-repeat;
    background-size: 14px;
    cursor: pointer;
    img {
      width: 24px;
      margin-right: 7px;
    }
  }
  dl {
    margin-top: 24px;
    padding-bottom: 63px;
  }
  dt {
    position: relative;
    width: max-content;
    img {
      width: 128px;
      height: 125px;
    }
  }
  .onPayment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.63);
    z-index: 99;
    padding-top: 32px;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    .icon {
      // color: $primaryColor;
      color: #52c41a;
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
  dd {
    margin-top: 16px;
    font-size: 14px;
    color: #1c67f3;
    line-height: 19px;
    padding-left: 15px;
    cursor: pointer;
  }
}
</style>