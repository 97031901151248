<template>
  <div class="typesMain">
    <div class="typesBox" @click="nextStep(0)">
      <em>电子版</em>
      <img src="@/assets/icons/service/electron.png">
      <dl>
        <dt>豸信CA验签报告-电子版</dt>
        <dd>可证明然署文栏的有效性，生成电子版证据报告，加盖机构电子印章</dd>
      </dl>
      <!-- <p>100元/份</p> -->
    </div>
    <div class="typesBox" @click="nextStep(1)">
      <em>纸质版</em>
      <img src="@/assets/icons/service/paper.png">
      <dl>
        <dt>豸信CA验签报告-纸质版</dt>
        <dd>可证明签署文档的有效性，加盖机构实体章原文和证据报告骑缝章</dd>
      </dl>
      <!-- <p>200元/份</p> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.getAccount()
  },
  methods: {
    nextStep (name) {
      this.$parent.stepActive = 2
      setTimeout(() => {
        this.$parent.setType(name)
      }, 0);
    },
    getAccount () {
      this.$api.getAccountInfo().then(res => {
        if (!res.data.verified) {
          this.$confirm('此业务需要【管理员认证】和【企业认证】成功之后才可申请。', '提示', {
            confirmButtonText: '去认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'account' })
          }).catch(() => {

          });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.typesMain {
  padding: 110px 0 320px;
  display: flex;
  justify-content: center;
}
.typesBox {
  width: 326px;
  height: 408px;
  text-align: center;
  background: linear-gradient(180deg, #e2ecff 0%, #fafbff 100%);
  border-radius: 2px;
  padding: 36px 0 34px;
  position: relative;
  cursor: pointer;
  &:first-child {
    margin-right: 48px;
  }
  &:hover {
    dt {
      color: #1c67f3;
    }
  }
  em {
    display: inline-block;
    width: 78px;
    height: 36px;
    background: linear-gradient(90deg, #1d68f3 0%, #6fbbff 100%);
    border-radius: 2px 0px 2px 0px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    line-height: 36px;
    cursor: pointer;
  }
  img {
    width: 136px;
    height: 196px;
    background: #d8d8d8;
    margin-bottom: 32px;
  }
  dl {
    padding: 0 22px 0 24px;
  }
  dt {
    font-size: 18px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 700;
    cursor: pointer;
  }
  dd {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    text-align: left;
    cursor: pointer;
  }
  p {
    margin-top: 24px;
    font-size: 12px;
    color: #1c67f3;
    line-height: 16px;
    cursor: pointer;
  }
}
</style>