<template>
  <div class="certificationBox" v-loading="statusLoad">
    <h2>申请出证</h2>
    <div class="certificationInfo">
      <p><i class="el-icon-warning"></i>您可以在【个人中心】—>【业务】—>【验签报告业务单】中查看。</p>
      <img src="@/assets/icons/service/check-success-icon.png">
      <div v-if="transactionType===1">
        <h3>申请成功</h3>
        <h4>豸信CA文档验签报告-纸质版申请成功，平台正在为您出证中，7个工作日内为您邮寄</h4>
      </div>
      <div v-else>
        <h3>{{transactionStatus > 3?'出证成功':'出证中'}}</h3>
        <h5 v-if="transactionStatus===3">豸信CA文档验签报告-电子版申请成功，平台正在为您出证中，请耐心等待如有问题请联系客服400-030-2013</h5>
        <div class="downloadReport" v-if="transactionStatus>3">
          <img src="@/assets/icons/service/electron.png">
          <dl>
            <dt>验签报告</dt>
            <dd>对文档的签署信息出具证据报告，具有法律效力</dd>
          </dl>
          <el-button type="text" @click="downloadReport">下载报告</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      statusLoad: false,
      transactionStatus: '',
      transactionType: '',
      reportUrl: '',
      timeout: ''
    }
  },
  props: ['transactionId'],
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      let self = this
      this.statusLoad = true
      this.$api.getReportResult({ transactionId: this.transactionId }).then(res => {
        this.statusLoad = false
        if (res.code === '0') {
          this.transactionStatus = res.data.transactionStatus
          this.transactionType = res.data.transactionType
          this.reportUrl = res.data.reportUrl
          if (res.data.transactionStatus < 4 && res.data.transactionType !== 1) {
            this.timeout = setTimeout(() => {
              self.getDetail()
            }, 3000)
          }
        }
      })
    },
    downloadReport () {
      window.open(this.reportUrl, '_blank')
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    this.timeout = ''
    clearTimeout(this.errorTime)
    this.errorTime = ''
  }
}
</script>

<style lang="scss" scoped>
.certificationBox {
  h2 {
    height: 52px;
    padding-left: 32px;
    font-size: 16px;
    color: #333333;
    line-height: 52px;
    border-bottom: 1px solid #dee2e8;
  }
}
.certificationInfo {
  padding: 24px 110px;
  p {
    width: 1080px;
    height: 30px;
    padding: 0 14px;
    background: #e3ebff;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  i {
    font-size: 16px;
    color: #1c67f3;
    margin-right: 12px;
  }
  > img {
    display: block;
    margin: 78px auto 0;
    width: 35px;
  }
  h3 {
    margin-top: 16px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 27px;
    letter-spacing: 1px;
    text-align: center;
  }
  h4 {
    padding: 0 343px;
    font-size: 14px;
    color: #303b50;
    line-height: 24px;
    font-weight: normal;
    text-align: center;
    margin-top: 16px;
  }
  h5 {
    padding: 0 343px;
    font-size: 12px;
    color: #6c737b;
    line-height: 24px;
    font-weight: normal;
    text-align: center;
    margin-top: 16px;
  }
}
.downloadReport {
  padding: 8px 32px 8px 16px;
  width: 507px;
  height: 82px;
  background: #ffffff;
  box-shadow: 2px 2px 4px 0px rgba(29, 38, 51, 0.1);
  border-radius: 2px;
  border: 1px solid #dee2e8;
  margin: 32px auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  img {
    width: 46px;
    background: #d8d8d8;
    margin-right: 16px;
  }
  dl {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  dt {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 2px;
  }
  dd {
    font-size: 12px;
    color: #6c737b;
    line-height: 24px;
  }
}
</style>