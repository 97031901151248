<template>
  <div class="certApply">
    <div class="returnCrumbs">
      <router-link :to="{name:'serviceHall'}">
        服务大厅
      </router-link>
      <span>></span>
      <el-button type="text" :class="{'crumbsActive':stepActive===1}" @click="changeSteps">验签报告</el-button>
      <span v-if="stepActive>=2">></span>
      <el-button type="text" :class="{'crumbsActive':stepActive>=2}" v-if="stepActive>=2">豸信CA验签报告-{{reportType===0?'电子版':'纸质版'}}申请</el-button>
    </div>
    <div class="step-content">
      <reportType v-if="stepActive===1" />
      <uploadPdf ref="uploadPdf" v-if="stepActive===2" />
      <onlinePayment v-if="stepActive===3" :transactionId='transactionId' />
      <reportResult v-if="stepActive===4" :transactionId='transactionId' />
    </div>
  </div>
</template>

<script>
import reportType from './reportType.vue';
import uploadPdf from './uploadPdf.vue';
import onlinePayment from './onlinePayment.vue';
import reportResult from './reportResult.vue';
export default {
  data () {
    return {
      stepActive: 1,
      reportType: 0,
      transactionId: ''
    }
  },
  components: {
    reportType,
    uploadPdf,
    onlinePayment,
    reportResult
  },
  created () {
    let transactionId = this.$route.query.transactionId
    if (transactionId) {
      this.transactionId = transactionId
    } else {
      this.stepActive = 1
    }
  },
  methods: {
    changeSteps () {
      this.stepActive = 1
      this.transactionId = ''
      this.$router.push({ query: {}, })
    },
    setType (name) {
      this.reportType = name
      this.$refs.uploadPdf.reportType = name
    },
    getReportDetail () {
      this.$api.getReportResult({ transactionId: this.transactionId }).then(res => {
        this.statusLoad = false
        if (res.code === '0') {
          this.reportType = res.data.transactionType
          if (res.data.transactionStatus === 1) {
            this.stepActive = 3
          } else if (res.data.transactionStatus === 7) {
            this.stepActive = 4
          }
        }
      })
    }
  },
  watch: {
    transactionId (val) {
      if (val) {
        this.getReportDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-content {
  min-height: calc(100vh - 130px);
  border: none !important;
}
</style>